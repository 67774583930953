import { hidePopup } from "../helpers/popup";
import { hideMobileMenu, isMenuVisible, showMobileMenu } from "./mobile-menu";

$("a:not(.not-external)").each(function () {
  if (this.hostname.length && location.hostname !== this.hostname) {
    $(this).addClass("leaving");
  }
});

$("body").on("click", ".leaving", function (e) {
  e.preventDefault();
  if ($(".searchClicker").length == 0) {
    var clickDiv = $("<div class='searchClicker'>");
    var url = this.href;
    var blank = this.target.toLowerCase() === "_blank";
    var baseUrl = this.href.split("/")[2];
    var disclaimerFullHtml = disclaimerHtml.replace("[externalURL]", baseUrl);
    var disclaimerText = "<p>" + disclaimerFullHtml + "</p>";
    var disclaimer = $(
      '<div class="teaserbox disclaimer">' +
        disclaimerText +
        '<span class="link_button_variable"><a class="stay-on-page" href="#">abbrechen</a></span><span class="link_button_variable"><a href="' +
        url +
        '"' +
        (blank ? ' target="_blank"' : "") +
        ">fortfahren</a></span></div>",
    );
    const mobileMenuVisible = isMenuVisible();
    const hideDisclaimer = () => hidePopup(mobileMenuVisible && showMobileMenu);

    clickDiv.append(disclaimer);
    if ($(this).closest(".info-popup").length) {
      clickDiv.css("z-index", 201);
    }
    $("body").append(clickDiv);

    clickDiv.on("click", function (e) {
      if (e.currentTarget.className !== "searchClicker") return;

      hideDisclaimer();
    });
    disclaimer.on("click", function (e) {
      e.stopPropagation();
    });
    disclaimer.on("click", ".stay-on-page", function (e) {
      e.preventDefault();
      hideDisclaimer();
    });
    $(clickDiv).fadeIn();
    if (mobileMenuVisible) {
      hideMobileMenu();
    }
  }
  return false;
});
