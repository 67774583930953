import { mediathekLoadMoreVideos } from "./mediathek";
import { Utils } from "../helpers/utils";

(() => {
  //dropdowns
  const sameWidth = {
    name: "sameWidth",
    enabled: true,
    phase: "beforeWrite",
    requires: ["computeStyles"],
    fn: ({ state }) => {
      state.styles.popper.width = `${state.rects.reference.width}px`;
    },
    effect: ({ state }) => {
      state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    },
  };

  const popperInstances = [];
  const params = Utils.queryToParams(location.search);
  let noHistory = true;
  let searching = false;

  document.querySelectorAll("[data-toggle~=dropdown]").forEach(initDropdown);

  function initDropdown(dropdownToggle) {
    const dropdown = dropdownToggle.parentNode;
    const dropdownMenu = dropdown.querySelector(".dropdown-menu");

    dropdown.style.position = "static";

    const popperInstance = Popper.createPopper(dropdownToggle, dropdownMenu, {
      placement: "bottom-start",
      modifiers: [sameWidth],
    });
    popperInstances.push(popperInstance);

    dropdownToggle.setAttribute("aria-haspopup", "true");
    dropdownToggle.setAttribute("aria-expanded", "false");
    dropdownMenu.setAttribute("aria-hidden", "true");

    function toggleDropdown(e) {
      if (dropdownToggle.getAttribute("aria-expanded") === "true") {
        closeDropdown();
      } else {
        openDropdown();
        window.dispatchEvent(new CustomEvent("scroll"));
      }
    }
    function closeDropdown() {
      dropdownToggle.setAttribute("aria-expanded", "false");
      dropdownMenu.setAttribute("aria-hidden", "true");
      dropdown.classList.remove("dropdown-on");
    }
    function openDropdown() {
      dropdownToggle.setAttribute("aria-expanded", "true");
      dropdownMenu.setAttribute("aria-hidden", "false");
      dropdown.classList.add("dropdown-on");
      //set focus to first child
      dropdownMenu.children[0].focus();
    }

    dropdownToggle.addEventListener("click", toggleDropdown);

    document.addEventListener("click", (event) => {
      if (dropdown.contains(event.target)) return;
      //Clicked outside
      closeDropdown();
    });

    initFilterCheckboxes(dropdown);
  }

  function initFilterCheckboxes(dropdown) {
    const dropdownToggleTextInitial = dropdown.querySelector(".dropdown-text").dataset.text;

    // behaviour checkboxes
    const dropdownToggleText = dropdown.querySelector(".dropdown-text");
    const filterCheckboxes = dropdown.querySelectorAll('input[type="checkbox"].justone');

    let total = 0;

    filterCheckboxes.forEach(function (filterCheckbox) {
      filterCheckbox.addEventListener("change", function (event) {
        const checkbox = event.target;
        if (checkbox.checked) {
          checkbox.parentNode.classList.add("checked");
        } else {
          checkbox.parentNode.classList.remove("checked");
        }

        total = dropdown.querySelectorAll('input[type="checkbox"].justone:checked').length;

        if (total === 0) {
          dropdownToggleText.textContent = dropdownToggleTextInitial;
        } else {
          dropdownToggleText.textContent = "(" + total + ") ausgewählt";
        }

        if (dropdown.classList.contains("dropdown--mediathek")) {
          mediathekLoadMoreVideos.filterMediathek();
        } else {
          //liveSearch();
          debouncedLiveSearch();
        }
      });
    });
  }

  const cards = document.querySelectorAll(".download-list .teaser_box:not(.teaser_box--login)");
  const noResults = document.querySelector("#no-results");
  const resetSearchButton = document.querySelector("#reset-download-search");
  const resetFilterButton = document.querySelector("#reset-download-filters");

  if (resetFilterButton !== null) {
    resetFilterButton.addEventListener("click", function () {
      resetFilter();
    });
  }
  if (resetSearchButton !== null) {
    resetSearchButton.addEventListener("click", function () {
      document.getElementById("download-search-input").value = "";
      liveSearch();
    });
  }
  const resetMediathekFilterButton = document.querySelector("#reset-mediathek-filters");
  if (resetMediathekFilterButton !== null) {
    resetMediathekFilterButton.addEventListener("click", function () {
      const allFilterCheckboxes = document.querySelectorAll('.dropdown--mediathek input[type="checkbox"].justone');
      allFilterCheckboxes.forEach(function (opt) {
        opt.checked = false;
        const changeEvent = new Event("change");
        opt.dispatchEvent(changeEvent);
      });
    });
  }

  const downloadSearchButton = document.querySelector("#download-search");
  if (downloadSearchButton !== null) {
    downloadSearchButton.addEventListener("submit", function (event) {
      event.preventDefault();
      liveSearch();
    });
  }

  function resetFilter() {
    const allFilterCheckboxes = document.querySelectorAll('.download-filter input[type="checkbox"].justone');
    allFilterCheckboxes.forEach(function (opt) {
      opt.checked = false;
      const changeEvent = new Event("change");
      opt.dispatchEvent(changeEvent);
    });
  }
  function setFiltersFromHistory(queryParams) {
    const filterList = queryParams.split(",");

    noHistory = true;
    resetFilter();

    filterList.forEach((element, index) => {
      const inputElement = document.querySelector('[value="' + element + '"]');
      inputElement.checked = true;
      inputElement.parentNode.classList.add("checked");
      inputElement.dispatchEvent(new Event("change"));
    });
  }

  function debouncedLiveSearch() {
    searching = true;
    let timeout;
    timeout = setTimeout(() => {
      if (searching) {
        liveSearch();
        searching = false;
      }
    }, 200);
  }
  function liveSearch() {
    const searchTerm = document.getElementById("download-search-input").value;

    //get checked filter checkboxes
    const selectedTagsGrouped = Array.from(document.querySelectorAll(".dropdown-menu"))
      .map(function (el) {
        return Array.from(el.querySelectorAll('input[type="checkbox"].justone:checked')).map(function (el) {
          return el.value;
        });
      })
      .filter(function (group) {
        return group.length;
      });

    //show/hide reset Button
    if (selectedTagsGrouped.length) {
      resetFilterButton.classList.remove("is-hidden");
    } else {
      resetFilterButton.classList.add("is-hidden");
    }
    if (searchTerm !== "") {
      resetSearchButton.classList.remove("is-hidden");
    } else {
      resetSearchButton.classList.add("is-hidden");
    }
    cards.forEach(function (card) {
      card.classList.add("is-hidden");

      const cardTagsIds = Array.from(card.querySelectorAll("[data-tag-id")).map(function (cardTag) {
        return cardTag.dataset.tagId;
      });

      const tagMatches = selectedTagsGrouped.every(function (group) {
        return group.some(function (tag) {
          return cardTagsIds.includes(tag);
        });
      });

      if (selectedTagsGrouped.length > 0) {
        if (tagMatches && card.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
          card.classList.remove("is-hidden");
        }
      } else if (card.textContent.toLowerCase().includes(searchTerm.toLowerCase())) {
        card.classList.remove("is-hidden");
      }
    });
    const params = Utils.queryToParams(location.search);

    let addHistoryEntry = false;
    if (!noHistory) {
      if (!params["filterTags"] || params["filterTags"].length !== selectedTagsGrouped.length) {
        addHistoryEntry = true;
      }
    }

    if (addHistoryEntry) {
      if (selectedTagsGrouped.length) {
        params["filterTags"] = selectedTagsGrouped;
      } else {
        delete params["filterTags"];
      }
      history.pushState(null, null, location.origin + location.pathname + Utils.paramsToQuery(params) + location.hash);
    }

    //show no-results div when no card matches
    if (noResults !== null) {
      if (document.querySelectorAll(".download-list .teaser_box.is-hidden:not(.teaser_box--login)").length === cards.length) {
        noResults.classList.remove("is-hidden");
      } else {
        noResults.classList.add("is-hidden");
      }
    }
    popperInstances.forEach((popperInstance) => popperInstance.update());

    noHistory = false;
  }

  window.addEventListener(
    "popstate",
    function () {
      const params = Utils.queryToParams(location.search);
      if (!params["filterTags"]) {
        noHistory = true;
        resetFilter();
      } else if (params["filterTags"]) {
        setFiltersFromHistory(params["filterTags"]);
      }
    },
    { passive: true }
  );

  if (!params["filterTags"]) {
    resetFilter();
  } else if (params["filterTags"]) {
    setFiltersFromHistory(params["filterTags"]);
  }
})();
