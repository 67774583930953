/** @type {NodeListOf<HTMLDivElement>} */
const cards = document.querySelectorAll(".target-group-teaser");
cards.forEach((card) => {
  /** @type {HTMLLinkElement} */
  const link = card.querySelector(".target-group-teaser__link");
  if (!link) return;

  card.classList.add("target-group-teaser--linked");

  /** @param {MouseEvent} event */
  const isNewTabClick = (event) => {
    return event.button === 1 || (isCurrentTabClick(event) && event.ctrlKey);
  };

  /** @param {MouseEvent} event */
  const isCurrentTabClick = (event) => event.button === 0;

  /** @type {number} */
  let down;
  card.addEventListener("mousedown", (event) => {
    if (isNewTabClick(event)) {
      event.preventDefault();
      return;
    }

    if (isCurrentTabClick(event)) {
      down = Date.now();
    }
  });
  card.addEventListener("mouseup", (event) => {
    if (isNewTabClick(event)) {
      window.open(link.href, "_blank");
      return;
    }

    if (isCurrentTabClick(event) && Date.now() - down < 200) {
      link.click();
    }
  });
});
