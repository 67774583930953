$('.js-image-slider').slick({
  prevArrow:
    '<button type="button" class="slick-prev image-slider__arrow image-slider__arrow--prev"><span class="sr-only">Previous</span></button>',
  nextArrow:
    '<button type="button" class="slick-next image-slider__arrow image-slider__arrow--next"><span class="sr-only">Next</span></button>',
  dots: true,
  dotsClass: 'image-slider__dots',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
});
