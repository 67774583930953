(function () {
  // podcast
  const podcastContainer = document.querySelectorAll("[data-rss-feed]");
  const loggedIn = document.body.classList.contains("loggedin");

  if (podcastContainer.length === 0) return;

  podcastContainer.forEach(function (el) {
    const feedLive = el.dataset.rssFeed;
    const feedProtected = el.dataset.podcastFeed;

    let podcastInfo = [];

    function fetchFeed(url, protected, withIntro) {
      if (!url) return;

      return fetch(url)
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          //console.log(data);

          if (withIntro) {
            const podcastIntro = {
              img: data.querySelector("image url").innerHTML,
              title: data.querySelector("title").innerHTML,
              description: data.querySelector("description").innerHTML,
              link: data.querySelector("description+link").innerHTML,
              items: data.querySelectorAll("item").length,
            };
            podcastInfo.push(podcastIntro);
          }

          const items = data.querySelectorAll("item");
          return Array.from(items).map((el) => {
            const date = new Date(el.querySelector("pubDate").innerHTML);
            const formatedDate = formatDate(date);
            const order = formateDateToOrderString(date);

            const podcastObj = {
              order: order,
              date: formatedDate,
              protected: protected,
              title: el.querySelector("title").innerHTML,
              link: el.querySelector("link").innerHTML,
              description: el.querySelector("guid").nextElementSibling.innerHTML,
              url: el.querySelector("enclosure").getAttribute("url"),
              duration: calculateTime(el.getElementsByTagName("itunes:duration")[0].innerHTML),
            };
            return podcastObj;
          });
        });
    }

    if (!feedLive) {
      fetchFeed(feedProtected, true, true)
        .then((podcastObj) => {
          renderScreen(podcastInfo, podcastObj);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (!feedProtected) {
      fetchFeed(feedLive, false, true)
        .then((podcastObj) => {
          renderScreen(podcastInfo, podcastObj);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const promises = [fetchFeed(feedLive, false, true), fetchFeed(feedProtected, true, false)];
      Promise.all(promises)
        .then(([podcastObj1, podcastObj2]) => {
          const allPodcasts = podcastObj1.concat(podcastObj2).sort(function (a, b) {
            return b.order.localeCompare(a.order);
          });
          renderScreen(podcastInfo, allPodcasts);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function renderScreen(podcastInfo, allPodcasts) {
      if (el.classList.contains("podcast-container--overview")) {
        renderPodcastsOverview(podcastInfo, allPodcasts.length);
      }
      if (el.classList.contains("podcast-container--list")) {
        renderPodcastsInfo(podcastInfo, allPodcasts.length);
        renderPodcasts(allPodcasts);
      }
    }

    function formatDate(input) {
      const date = new Date(input);
      const months = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
      const formatedDate = date.getDate() + ". " + months[date.getMonth()] + " " + date.getFullYear();
      return formatedDate;
    }

    function formateDateToOrderString(input) {
      const date = new Date(input);
      const orderString = date.getFullYear() + "" + addZ(date.getMonth()) + "" + addZ(date.getDate());
      return orderString;
    }

    function addZ(n) {
      return n < 10 ? "0" + n : "" + n;
    }

    function calculateTime(secs) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    }

    /**
     * https://github.com/huang47/nodejs-html-truncate
     * Truncate HTML string and keep tag safe.
     *
     * @method truncate
     * @param {String} string string needs to be truncated
     * @param {Number} maxLength length of truncated string
     * @param {Object} options (optional)
     * @param {Boolean} [options.keepImageTag] flag to specify if keep image tag, false by default
     * @param {Boolean} [options.truncateLastWord] truncates last word, true by default
     * @param {Number} [options.slop] tolerance when options.truncateLastWord is false before we give up and just truncate at the maxLength position, 10 by default (but not greater than maxLength)
     * @param {Boolean|String} [options.ellipsis] omission symbol for truncated string, '...' by default
     * @return {String} truncated string
     */
    function truncate(string, maxLength, options) {
      var EMPTY_OBJECT = {},
        EMPTY_STRING = "",
        DEFAULT_TRUNCATE_SYMBOL = "...",
        DEFAULT_SLOP = 10 > maxLength ? maxLength : 10,
        EXCLUDE_TAGS = ["img", "br"], // non-closed tags
        items = [], // stack for saving tags
        total = 0, // record how many characters we traced so far
        content = EMPTY_STRING, // truncated text storage
        KEY_VALUE_REGEX = '([\\w|-]+\\s*=\\s*"[^"]*"\\s*)*',
        IS_CLOSE_REGEX = "\\s*\\/?\\s*",
        CLOSE_REGEX = "\\s*\\/\\s*",
        SELF_CLOSE_REGEX = new RegExp("<\\/?\\w+\\s*" + KEY_VALUE_REGEX + CLOSE_REGEX + ">"),
        HTML_TAG_REGEX = new RegExp("<\\/?\\w+\\s*" + KEY_VALUE_REGEX + IS_CLOSE_REGEX + ">"),
        URL_REGEX = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)|((mailto:)?[_.\w\-]+@([\w][\w\-]+\.)+[a-zA-Z]{2,3})/g, // Simple regexp
        IMAGE_TAG_REGEX = new RegExp("<img\\s*" + KEY_VALUE_REGEX + IS_CLOSE_REGEX + ">"),
        WORD_BREAK_REGEX = new RegExp("\\W+", "g"),
        matches = true,
        result,
        index,
        tail,
        tag,
        selfClose;

      /**
       * Remove image tag
       *
       * @private
       * @method _removeImageTag
       * @param {String} string not-yet-processed string
       * @return {String} string without image tags
       */
      function _removeImageTag(string) {
        var match = IMAGE_TAG_REGEX.exec(string),
          index,
          len;

        if (!match) {
          return string;
        }

        index = match.index;
        len = match[0].length;

        return string.substring(0, index) + string.substring(index + len);
      }

      /**
       * Dump all close tags and append to truncated content while reaching upperbound
       *
       * @private
       * @method _dumpCloseTag
       * @param {String[]} tags a list of tags which should be closed
       * @return {String} well-formatted html
       */
      function _dumpCloseTag(tags) {
        var html = "";

        tags.reverse().forEach(function (tag, index) {
          // dump non-excluded tags only
          if (-1 === EXCLUDE_TAGS.indexOf(tag)) {
            html += "</" + tag + ">";
          }
        });

        return html;
      }

      /**
       * Process tag string to get pure tag name
       *
       * @private
       * @method _getTag
       * @param {String} string original html
       * @return {String} tag name
       */
      function _getTag(string) {
        var tail = string.indexOf(" ");

        // TODO:
        // we have to figure out how to handle non-well-formatted HTML case
        if (-1 === tail) {
          tail = string.indexOf(">");
          if (-1 === tail) {
            throw new Error("HTML tag is not well-formed : " + string);
          }
        }

        return string.substring(1, tail);
      }

      /**
       * Get the end position for String#substring()
       *
       * If options.truncateLastWord is FALSE, we try to the end position up to
       * options.slop characters to avoid breaking in the middle of a word.
       *
       * @private
       * @method _getEndPosition
       * @param {String} string original html
       * @param {Number} tailPos (optional) provided to avoid extending the slop into trailing HTML tag
       * @return {Number} maxLength
       */
      function _getEndPosition(string, tailPos) {
        var defaultPos = maxLength - total,
          position = defaultPos,
          isShort = defaultPos < options.slop,
          slopPos = isShort ? defaultPos : options.slop - 1,
          substr,
          startSlice = isShort ? 0 : defaultPos - options.slop,
          endSlice = tailPos || defaultPos + options.slop,
          result;

        if (!options.truncateLastWord) {
          substr = string.slice(startSlice, endSlice);

          if (tailPos && substr.length <= tailPos) {
            position = substr.length;
          } else {
            while ((result = WORD_BREAK_REGEX.exec(substr)) !== null) {
              // a natural break position before the hard break position
              if (result.index < slopPos) {
                position = defaultPos - (slopPos - result.index);
                // keep seeking closer to the hard break position
                // unless a natural break is at position 0
                if (result.index === 0 && defaultPos <= 1) break;
              }
              // a natural break position exactly at the hard break position
              else if (result.index === slopPos) {
                position = defaultPos;
                break; // seek no more
              }
              // a natural break position after the hard break position
              else {
                position = defaultPos + (result.index - slopPos);
                break; // seek no more
              }
            }
          }
          if (string.charAt(position - 1).match(/\s$/)) position--;
        }
        return position;
      }

      options = options || EMPTY_OBJECT;
      options.ellipsis = undefined !== options.ellipsis ? options.ellipsis : DEFAULT_TRUNCATE_SYMBOL;
      options.truncateLastWord = undefined !== options.truncateLastWord ? options.truncateLastWord : true;
      options.slop = undefined !== options.slop ? options.slop : DEFAULT_SLOP;

      while (matches) {
        matches = HTML_TAG_REGEX.exec(string);

        if (!matches) {
          if (total >= maxLength) {
            break;
          }

          matches = URL_REGEX.exec(string);
          if (!matches || matches.index >= maxLength) {
            content += string.substring(0, _getEndPosition(string));
            break;
          }

          while (matches) {
            result = matches[0];
            index = matches.index;
            content += string.substring(0, index + result.length - total);
            string = string.substring(index + result.length);
            matches = URL_REGEX.exec(string);
          }
          break;
        }

        result = matches[0];
        index = matches.index;

        if (total + index > maxLength) {
          // exceed given `maxLength`, dump everything to clear stack
          content += string.substring(0, _getEndPosition(string, index));
          break;
        } else {
          total += index;
          content += string.substring(0, index);
        }

        if ("/" === result[1]) {
          // move out open tag
          items.pop();
          selfClose = null;
        } else {
          selfClose = SELF_CLOSE_REGEX.exec(result);
          if (!selfClose) {
            tag = _getTag(result);

            items.push(tag);
          }
        }

        if (selfClose) {
          content += selfClose[0];
        } else {
          content += result;
        }
        string = string.substring(index + result.length);
      }

      if (string.length > maxLength - total && options.ellipsis) {
        content += options.ellipsis;
      }
      content += _dumpCloseTag(items);

      if (!options.keepImageTag) {
        content = _removeImageTag(content);
      }

      return content;
    }

    function renderPodcastsInfo(data, count) {
      el.querySelector(".podcast__img").src = data[0].img;
      el.querySelector(".podcast__count").innerHTML = count + " Folgen";
      el.querySelector(".podcast__details p").innerHTML = data[0].description;
      el.querySelectorAll(".skeleton").forEach(function (skeleton) {
        skeleton.classList.remove("skeleton");
      });
    }

    function renderPodcastsOverview(data, count) {
      el.querySelector(".podcast__img").src = data[0].img;
      el.querySelector(".podcast__count").innerHTML = count + " Folgen";
      el.querySelector(".podcast__details p").innerHTML = data[0].description;
      el.querySelectorAll(".skeleton").forEach(function (skeleton) {
        skeleton.classList.remove("skeleton");
      });
    }

    function renderPodcasts(data) {
      //console.log(data);
      let html = "";

      data.forEach((element) => {
        if (element.protected && !loggedIn) {
          html += `
            <article class="podcast__episode podcast__episode--private">
              <div class="podacst__date">${element.date}</div>
              <h3 class="">
                <svg class="ico-protected" width="19" height="21" viewBox="0 0 217.8 310.4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M106.8 6.1c-44.1 1.1-79.3 37-79.3 81.4v34.6H6.1V242.5c0 34.2 25.1 61.7 56.2 61.7h93.1c31.1 0 56.2-27.5 
                  56.2-61.7 0-.9 0-1.7-.1-2.6h.1V122.2h-21.3V87.5c0-45.1-36.3-81.4-81.4-81.4h-2.1zm-.4 23.7h1.6c25.8-.6 51.1 18.5 
                  56.4 43.8.9 16.2 1.4 32.3 1.7 48.5H51.8c0-7.7-.1-15.5-.2-23.2-6.5-33 21.1-68.3 54.8-69.1zm2.4 135.4c1.2 0 2.4.1 3.7.3 
                  13.2 2 22.4 14.3 20.4 27.5-.9 6.1-4.1 11.3-8.4 15l8.5 53.1H85l8.4-53.1c-6.2-5.2-9.7-13.5-8.4-22.2 1.7-11.9 12-20.5 23.8-20.6z" 
                  fill="currentColor"/>
                </svg>
                ${element.title}
              </h3>
              <p>Sie sind Arzt/Ärztin? Loggen Sie sich ein und sehen Sie weitere Folgen.</p>
              <button type="button" onclick="javascript:login();return false;" class="btn btn--dc">
                <svg class="ico-protected" width="17" height="25" viewBox="0 0 217.8 310.4" xmlns="http://www.w3.org/2000/svg">
                  <path d="M106.8 6.1c-44.1 1.1-79.3 37-79.3 81.4v34.6H6.1V242.5c0 34.2 25.1 61.7 56.2 61.7h93.1c31.1 0 56.2-27.5 56.2-61.7 
                  0-.9 0-1.7-.1-2.6h.1V122.2h-21.3V87.5c0-45.1-36.3-81.4-81.4-81.4h-2.1zm-.4 23.7h1.6c25.8-.6 51.1 18.5 56.4 43.8.9 16.2 1.4 
                  32.3 1.7 48.5H51.8c0-7.7-.1-15.5-.2-23.2-6.5-33 21.1-68.3 54.8-69.1zm2.4 135.4c1.2 0 2.4.1 3.7.3 13.2 2 22.4 14.3 20.4 27.5-.9 
                  6.1-4.1 11.3-8.4 15l8.5 53.1H85l8.4-53.1c-6.2-5.2-9.7-13.5-8.4-22.2 1.7-11.9 12-20.5 23.8-20.6z" 
                  fill="currentColor"/>
                </svg>
                Zum DocCheck Login
                <svg class="ico-arrow" width="30" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 19">
                  <path d="m18.5 0-2.1 2.1L22.3 8H0v3h22.3l-5.9 5.9 2.1 2.1 7.4-7.4L28 9.5l-2.1-2.1L18.5 0z" 
                  fill="currentColor"/>
                </svg>
              </button>
            </article>
            `;
        } else {
          html += `
            <article class="podcast__episode">
              <div class="podacst__date">${element.date}</div>
              <h3>${element.title}</h3>
              <div class="podcast__description">
                <div class="podcast__shortdescription">${truncate(element.description.replace("<![CDATA[", "").replace("]]>", ""), 200)}</div>
                <div class="podcast__longdescription" aria-hidden>${element.description.replace("<![CDATA[", "").replace("]]>", "")}</div>
                <button type="button" class="js-podcast-more podcast-more">
                  <span>mehr anzeigen</span>
                  <span aria-hidden>weniger anzeigen</span>
                </button>
              </div>
              <button type="button" class="podcast__button" data-src="${element.url}">
                <svg class="ico-play" width="10" height="11" viewBox="0 0 10 11" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.90625 10.2541V1.14774C0.90625 0.769187 1.31627 0.532789 1.64387 0.722456L9.50849 5.27565C9.83542
                  5.46493 9.83542 5.93694 9.50849 6.12621L1.64388 10.6794C1.31627 10.8691 0.90625 10.6327 0.90625 10.2541Z" 
                  fill="currentColor"/>
                </svg>
                Wiedergabe
              </button>
              <div class="podcast__duration">${element.duration} Min.</div>
            </article>
            `;
        }
      });

      document.querySelector(".podcast-container--list .podcast__details").insertAdjacentHTML("beforeend", html);
    }

    el.addEventListener("click", function (event) {
      const toggleBtn = event.target.closest(".js-podcast-more");
      if (!toggleBtn) return;

      if (toggleBtn.getAttribute("aria-expanded") === "true") {
        toggleBtn.setAttribute("aria-expanded", "false");
      } else {
        toggleBtn.setAttribute("aria-expanded", "true");
      }
      //toggleBtn.previousElementSibling.classList.toggle('line-clampxxx');

      toggleBtn.parentElement.querySelector(".podcast__shortdescription").toggleAttribute("aria-hidden");
      toggleBtn.parentElement.querySelector(".podcast__longdescription").toggleAttribute("aria-hidden");

      toggleBtn.querySelector("span:first-child").toggleAttribute("aria-hidden");
      toggleBtn.querySelector("span:nth-child(2)").toggleAttribute("aria-hidden");
    });

    el.addEventListener("click", function (event) {
      const podcastBtn = event.target.closest(".podcast__button");
      if (!podcastBtn) return;

      const audioPlayer = document.createElement("audio-player");
      audioPlayer.setAttribute("data-src", podcastBtn.dataset.src);
      audioPlayer.setAttribute("autoplay", "autoplay");
      podcastBtn.nextElementSibling.remove(); //remove duration
      podcastBtn.after(audioPlayer);
      podcastBtn.remove();
    });
  });
})();

(function () {
  //AudioPlayer
  class AudioPlayer extends HTMLElement {
    constructor() {
      super();
      const template = document.querySelector("template.audioplayer");
      const templateContent = template.content;
      const shadow = this.attachShadow({ mode: "open" });
      shadow.appendChild(templateContent.cloneNode(true));
    }

    connectedCallback() {
      everything(this);
    }
  }

  const everything = function (element) {
    const shadow = element.shadowRoot;

    const audioPlayerContainer = shadow.getElementById("audio-player-container");
    const playIconContainer = shadow.getElementById("play-icon");
    const seekSlider = shadow.getElementById("seek-slider");
    //const muteIconContainer = shadow.getElementById("mute-icon");
    const audio = shadow.querySelector("audio");
    const durationContainer = shadow.getElementById("duration");
    const currentTimeContainer = shadow.getElementById("current-time");
    const skipBackward = shadow.getElementById("backward");
    const skipForward = shadow.getElementById("forward");

    let playState = "play";
    let muteState = "unmute";
    let raf = null;
    const playAudio = new CustomEvent("playerstarted", {
      detail: audioPlayerContainer,
    });

    document.addEventListener("playerstarted", (e) => {
      if (e.detail !== audioPlayerContainer && playState === "pause") {
        audio.pause();
        cancelAnimationFrame(raf);
        playState = "play";
        playIconContainer.classList.remove("is-playing");
      }
    });

    audio.src = element.getAttribute("data-src");

    const whilePlaying = () => {
      seekSlider.value = Math.floor(audio.currentTime);
      currentTimeContainer.textContent = calculateTime(seekSlider.value);
      if (!isNaN(audio.duration)) {
        durationContainer.textContent = "-" + calculateTime(audio.duration - seekSlider.value);
      }
      audioPlayerContainer.style.setProperty("--seek-before-width", `${(seekSlider.value / seekSlider.max) * 100}%`);
      raf = requestAnimationFrame(whilePlaying);
    };

    const showRangeProgress = (rangeInput) => {
      if (rangeInput === seekSlider) audioPlayerContainer.style.setProperty("--seek-before-width", (rangeInput.value / rangeInput.max) * 100 + "%");
      else audioPlayerContainer.style.setProperty("--volume-before-width", (rangeInput.value / rangeInput.max) * 100 + "%");
    };

    const calculateTime = (secs) => {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${minutes}:${returnedSeconds}`;
    };

    const displayDuration = () => {
      durationContainer.textContent = calculateTime(audio.duration);
    };

    const setSliderMax = () => {
      seekSlider.max = Math.floor(audio.duration);
    };

    const displayBufferedAmount = () => {
      const bufferedAmount = Math.floor(audio.buffered.end(audio.buffered.length - 1));
      audioPlayerContainer.style.setProperty("--buffered-width", `${(bufferedAmount / seekSlider.max) * 100}%`);
    };

    if (audio.readyState > 0) {
      displayDuration();
      setSliderMax();
      displayBufferedAmount();
    } else {
      audio.addEventListener("loadedmetadata", () => {
        displayDuration();
        setSliderMax();
        displayBufferedAmount();
      });
    }

    playIconContainer.addEventListener("click", () => {
      if (playState === "play") {
        audio.play();
        requestAnimationFrame(whilePlaying);
        playState = "pause";
        // dispatch event for other players
        document.dispatchEvent(playAudio);
        playIconContainer.classList.add("is-playing");
      } else {
        audio.pause();
        cancelAnimationFrame(raf);
        playState = "play";
        playIconContainer.classList.remove("is-playing");
      }
    });
    if (element.getAttribute("autoplay")) {
      //audio.setAttribute('autoplay','autoplay');
      playIconContainer.click();
    }
    // muteIconContainer.addEventListener("click", () => {
    //   if (muteState === "unmute") {
    //     audio.muted = true;
    //     muteState = "mute";
    //   } else {
    //     audio.muted = false;
    //     muteState = "unmute";
    //   }
    // });

    audio.addEventListener("progress", displayBufferedAmount);

    seekSlider.addEventListener("input", (e) => {
      showRangeProgress(e.target);
      currentTimeContainer.textContent = calculateTime(seekSlider.value);
      if (!audio.paused) {
        cancelAnimationFrame(raf);
      }
    });

    seekSlider.addEventListener("change", () => {
      audio.currentTime = seekSlider.value;
      if (!audio.paused) {
        requestAnimationFrame(whilePlaying);
      }
    });

    skipForward.addEventListener("click", () => {
      audio.currentTime += 30;
    });

    skipBackward.addEventListener("click", () => {
      audio.currentTime -= 15;
    });
  };

  customElements.define("audio-player", AudioPlayer);
})();
