// Utils
const Utils = {
  queryToParams: function (query) {
    return query
      .replace("?", "")
      .split("&")
      .reduce(function (res, param) {
        if (param.indexOf("=") > -1) {
          const parts = param.split("=");
          res[parts[0]] = parts[1];
        } else if (param) {
          res[param] = true;
        }
        return res;
      }, {});
  },
  paramsToQuery: function (params) {
    const parts = [];
    for (const param in params) {
      if (!param) continue;
      if (params[param] === true) {
        parts.push(param);
      } else {
        parts.push(param + "=" + params[param]);
      }
    }
    if (parts.length) {
      return "?" + parts.join("&");
    }
    return "";
  },
};

export { Utils };
