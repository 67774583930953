(function () {
  const DCREFERER = document.querySelector('[name="dc_iframe_referer"]');

  if (!DCREFERER) return;

  let queryString = location.search;
  if(queryString.includes("returnUrl")){
    queryString = '';
  }

  DCREFERER.value += queryString + location.hash;

  const mediaQueryList = window.matchMedia("screen and (max-width : 1023px)");
  const closeDetails = function () {
    const dcInfo = document.querySelector('.doccheck__info-2');
    if(!dcInfo) return;
    dcInfo.removeAttribute('open');
  };
  const openDetails = function () {
    const dcInfo = document.querySelector('.doccheck__info-2');
    if(!dcInfo) return;
    dcInfo.setAttribute('open', 'open');
  };
  const onMatchMedia = function (event) {
    if (event.matches) {
      closeDetails();
    } else {
      openDetails();
    }
  };
  mediaQueryList.addEventListener("change", onMatchMedia, false);
  if (mediaQueryList.matches) closeDetails();
  
})();
