import { hidePopup } from "../helpers/popup";

const menu = document.querySelector("nav .menu_mobile");

export const isMenuVisible = () => menu?.classList.contains("visible");

export const showMobileMenu = () => {
  if (!menu || isMenuVisible()) return false;

  hidePopup();
  menu.classList.add("visible");
  document.body.style.overflow = "hidden";
  return true;
};

export const hideMobileMenu = () => {
  if (!menu || !isMenuVisible()) return false;

  menu.classList.remove("visible");
  document.body.style.overflow = "";
  return true;
};

$(".nav_hamburger").on("click", showMobileMenu);

$(".nav_hamburger_close").on("click", hideMobileMenu);
