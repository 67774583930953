const CONTACT = document.querySelector(".kontakt_lasche");
const HAMBURGER = document.querySelector(".nav_hamburger");
if (!CONTACT || !HAMBURGER) return;

const CONTACT_BUTTON = `
  <button type="button" class="contact-layer-trigger js-contact-trigger">
    <span class="sr-only">Kontakt</span>
  </button>
`;

HAMBURGER.insertAdjacentHTML("beforebegin", CONTACT_BUTTON);
document.querySelector(".js-contact-trigger").addEventListener("click", toggleContact);

function toggleContact(e) {
  const TOGGLE_LAYER = document.querySelector(".kontakt_box");
  if (!TOGGLE_LAYER) return;

  TOGGLE_LAYER.classList.toggle("kontakt_box--visible");
}
