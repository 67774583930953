import { Utils } from "../helpers/utils";

// Mediathek load more videos
var mediathekLoadMoreVideos = (function ($) {
  "use strict";

  function bindings() {
    $("body").on("click", ".js-mediathek-load-more-videos", function () {
      loadVideos(VIDEOGALLERY_PAGE, true);
    });

    window.addEventListener(
      "popstate",
      function () {
        const params = Utils.queryToParams(location.search);
        if (!params["videosTags"] && !params["videosTag"]) {
          resetMediathek();
        } else if (params["videosTags"]) {
          setVideoFiltersFromHistory(params["videosTags"]);
        } else if (params["videosTag"]) {
          setVideoFiltersFromHistory(params["videosTag"]); //decrecated
        }
      },
      { passive: true }
    );
  }

  function resetMediathek() {
    resetVideoFilters();

    const firstInputElement = document.querySelector('input[type="checkbox"].justone');
    firstInputElement.checked = true;
    firstInputElement.parentNode.classList.add("checked");
    const dropdownToggleText = document.querySelector(".dropdown-text");
    dropdownToggleText.textContent = dropdownToggleText.dataset.text;

    loadVideosFromFilter(true);
  }
  function resetVideoFilters() {
    const filterCheckboxesChecked = document.querySelectorAll('input[type="checkbox"].justone:checked');
    filterCheckboxesChecked.forEach(function (filterCheckbox) {
      filterCheckbox.checked = false;
      filterCheckbox.parentNode.classList.remove("checked");
    });
  }
  function setVideoFiltersFromHistory(queryParams) {
    const videoList = queryParams.split(",");

    resetVideoFilters();

    videoList.forEach((element, index) => {
      const inputElement = document.querySelector('[value="' + element + '"]');
      inputElement.checked = true;
      inputElement.parentNode.classList.add("checked");

      if (index === videoList.length - 1) {
        inputElement.dispatchEvent(new Event("change"));
      }
    });
  }

  const getActiveTagIds = function () {
    const filterCheckboxesChecked = document.querySelectorAll('input[type="checkbox"].justone:checked');
    const activeTagItemIds = Array.from(filterCheckboxesChecked).map(function (filterCheckbox) {
      return filterCheckbox.value;
    });

    return activeTagItemIds.join();
  };

  function loadVideosFromFilter(noHistory) {
    const activeTagItemIds = getActiveTagIds();

    const resetMediathekFilterButton = document.querySelector("#reset-mediathek-filters");
    //show/hide reset Button
    if (resetMediathekFilterButton !== null) {
      if (activeTagItemIds.length) {
        resetMediathekFilterButton.classList.remove("is-hidden");
      } else {
        resetMediathekFilterButton.classList.add("is-hidden");
      }
    }

    loadVideos(0, false);
    VIDEOGALLERY_PAGE = 1;
    const params = Utils.queryToParams(location.search);

    let addHistoryEntry = false;
    if (!noHistory) {
      if (!params["videosTags"] && !params["videosTag"]) {
        addHistoryEntry = true;
      } else if (params["videosTags"]) {
        if (params["videosTags"].length !== activeTagItemIds.length) {
          addHistoryEntry = true;
        }
      } else if (params["videosTag"]) {
        if (params["videosTag"].length !== activeTagItemIds.length) {
          addHistoryEntry = true;
        }
      }
    }

    if (addHistoryEntry) {
      if (activeTagItemIds) {
        params["videosTags"] = activeTagItemIds;
      } else {
        delete params["videosTags"];
      }
      history.pushState(null, null, location.origin + location.pathname + Utils.paramsToQuery(params) + location.hash);
    }
  }

  function loadVideos(page, append) {
    const activeTagItemIds = getActiveTagIds();
    const ajaxUrl = "/alexiongermanyapi/VideoGallery/VideoGallery";
    $.ajax({
      url: ajaxUrl,
      type: "get",
      async: false,
      data: {
        selectedTagIds: activeTagItemIds,
        dsItemId: VIDEOGALLERY_ID,
        page: page,
      },
      success: function (data) {
        if (data.trim() !== "") {
          if (append) {
            $(".videoteaserList.mediathek .loadmore-mediathek-wrapper").replaceWith(data);
            VIDEOGALLERY_PAGE++;
          } else {
            $(".videoteaserList.mediathek").html(data);
          }
          $(".js-mediathek-load-more-videos").removeClass("hidden");
        } else {
          if (!append) {
            $(".videoteaserList.mediathek").html("");
          }
          $(".js-mediathek-load-more-videos").addClass("hidden");
        }
      },
      error: function (data) {
        alert("error loading videos");
      },
    });
  }

  return {
    init: bindings,
    filterMediathek: loadVideosFromFilter,
  };
})(jQuery);
if (document.querySelectorAll(".mediathek").length > 0) {
  mediathekLoadMoreVideos.init();
}
// /Mediathek load more videos
export { mediathekLoadMoreVideos };
